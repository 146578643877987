var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 1288'},model:{value:(_vm.objInsurancePayment.member_number),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "member_number", $$v)},expression:"objInsurancePayment.member_number"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Customer ID'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 31111811411'},model:{value:(_vm.objInsurancePayment.identification),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "identification", $$v)},expression:"objInsurancePayment.identification"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Id sure'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 1027'},model:{value:(_vm.objInsurancePayment.insurance),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "insurance", $$v)},expression:"objInsurancePayment.insurance"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Currency type'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objInsurancePayment.coin_type),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "coin_type", $$v)},expression:"objInsurancePayment.coin_type"}},_vm._l((_vm.coinType),function(option){return _c('option',{key:option.id,domProps:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Amount'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 300950.09',"type":"number"},model:{value:(_vm.objInsurancePayment.amount),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "amount", $$v)},expression:"objInsurancePayment.amount"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Means'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objInsurancePayment.method_payment),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "method_payment", $$v)},expression:"objInsurancePayment.method_payment"}},_vm._l((_vm.listTypePaymentInsurance),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.payment_method)+" ")])}),0)],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objInsurancePayment.date),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "date", $$v)},expression:"objInsurancePayment.date"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Period from'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objInsurancePayment.period_from),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "period_from", $$v)},expression:"objInsurancePayment.period_from"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Period to'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objInsurancePayment.period_up_to),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "period_up_to", $$v)},expression:"objInsurancePayment.period_up_to"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{class:_vm.isAction == 'add' ? 'column' : 'column is-8'},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Remarks'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + ' No Hubo confirmacion de pago'},model:{value:(_vm.objInsurancePayment.observation),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "observation", $$v)},expression:"objInsurancePayment.observation"}})],1)]}}],null,true)})],1),(_vm.isAction == 'edit')?_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":"Actualizar póliza","type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t(
                  'The payment record will be updated and also the amount of the policy'
                )}},[_c('b-checkbox',{model:{value:(_vm.objInsurancePayment.update_insurance),callback:function ($$v) {_vm.$set(_vm.objInsurancePayment, "update_insurance", $$v)},expression:"objInsurancePayment.update_insurance"}})],1)]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }